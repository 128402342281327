import React, { useEffect } from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import logo from "../../../assets/logo-dark.svg";

function Register() {
  const { createOrg } = useKindeAuth();

  useEffect(() => {
    const autoRegister = async () => {
      try {
        await createOrg();
      } catch (error) {
        console.error("Auto-register failed:", error);
      }
    };

    autoRegister();
  });

  return (
    <div className="flex h-screen justify-center items-center bg-black">
      <div className="sm:px-12 lg:px-24 sm:py-8 lg:py-16 rounded-lg bg-white flex rounded-large items-center justify-center flex-col">
        <div className="pt-12 text-center">
          <img
            src={logo}
            alt="Opspot"
            className="mx-auto mb-6" // Centers and enlarges the image
          />
          <p className="mt-4 mb-6">
            Redirecting to registration...
            <br />
            If this redirect fails, please{" "}
            <a
              onClick={() => createOrg()}
              className="text-gray-600 cursor-pointer"
            >
              {" "}
              register here
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

export { Register };
