import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { AuthContext } from "../../../contexts/AuthContext";

const AuthCallback = () => {
  const { isAuthenticated, getToken, isLoading, user, getPermission } =
    useKindeAuth();
  const { setAuthContext } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const handleCallback = async () => {
      console.log("AuthCallback component mounted.");

      // Log the loading state and authentication status
      console.log("isLoading:", isLoading);
      console.log("isAuthenticated:", isAuthenticated);

      if (isLoading) {
        console.log("Still loading, waiting for authentication to complete...");
        return; // Wait until loading is complete
      }

      if (!isAuthenticated) {
        console.log("User is not authenticated, redirecting to login page...");
        navigate("/auth/login");
        return;
      }

      try {
        // Attempting to retrieve token
        console.log("Attempting to retrieve token...");
        const token = await getToken();
        if (!token) {
          console.error("Token retrieval failed. Token is not available.");
          throw new Error("Token is not available");
        }
        console.log("Token retrieved successfully:", token);

        // Sync user data with the backend or context
        console.log("Syncing user data with backend...");
        console.log("User object:", user);
        console.log("Permission object:", getPermission());

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/v1/users/sync`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              auth_user_id: user.id,
              auth_email: user.email,
              auth_org_code: getPermission().orgCode,
            }),
          }
        );

        // Check if the response was successful
        if (!response.ok) {
          console.error(
            "User data sync failed. Response status:",
            response.status
          );
          throw new Error(
            `Failed to sync user data. Status: ${response.status}`
          );
        }

        const data = await response.json();
        console.log("User data synced successfully:", data);

        // Update the auth context with the user data
        setAuthContext({
          userId: data.user_id,
          authId: user.id,
          orgId: data.org_id,
          orgName: data.org_name,
          authOrgId: getPermission().orgCode,
          firstName: user.given_name,
          lastName: user.family_name,
          email: user.email,
          onboardingComplete: data.onboarding_complete,
        });

        console.log("Auth context updated successfully with user data:", {
          userId: data.user_id,
          orgId: data.org_id,
          orgName: data.org_name,
        });

        // Redirect to homepage or onboarding based on user status
        const redirectUrl = data.onboarding_complete ? "/" : "/organization";
        console.log(
          "Onboarding status:",
          data.onboarding_complete,
          "Redirecting to:",
          redirectUrl
        );
        navigate(redirectUrl);
      } catch (error) {
        console.error(
          "Error occurred during the auth callback process:",
          error
        );
      }
    };

    // Trigger the callback once loading is complete and the user is authenticated
    if (!isLoading && isAuthenticated) {
      console.log(
        "Loading complete and user is authenticated. Triggering handleCallback..."
      );
      handleCallback();
    } else {
      console.log(
        "Either still loading or user is not authenticated. Waiting..."
      );
    }
  }, [
    isAuthenticated,
    isLoading,
    getToken,
    setAuthContext,
    navigate,
    user,
    getPermission,
  ]);

  return <div>Loading...</div>;
};

export { AuthCallback };
