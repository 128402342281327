import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const {
    isAuthenticated,
    isLoading,
    getToken,
    user,
    getOrganization,
    logout,
  } = useKindeAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const [initialCheckDone, setInitialCheckDone] = useState(false);

  // Initialize state with either localStorage or empty values
  const [authContext, setAuthContext] = useState(() => {
    const storedAuthContext = localStorage.getItem("authContext");
    return storedAuthContext
      ? JSON.parse(storedAuthContext)
      : {
          userId: "",
          authId: "",
          orgId: "",
          orgName: "",
          authOrgId: "",
          accessLevel: 1,
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          imageUrl: "",
          onboardingComplete: "",
        };
  });

  // Save authContext to localStorage on updates
  useEffect(() => {
    if (authContext.userId) {
      localStorage.setItem("authContext", JSON.stringify(authContext));
    }
  }, [authContext]);

  // Fetch user/org data when authenticated and not already loaded
  useEffect(() => {
    // Skip redirection if on the login page
    const isAuthRoute = location.pathname.includes("/auth/");

    if (isAuthRoute) {
      // Skip further processing for any /auth/ routes
      console.log("Skipping /auth/ routes...");
      return;
    }

    if (!isLoading && isAuthenticated && !authContext.userId) {
      if (user) {
        // Fetch fresh user/org data if userId is not already in authContext
        const apiUrl = `${process.env.REACT_APP_API_URL}/web/auth/login/${user.id}?detail=true`;
        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
            const localUserData = data[0];
            const newAuthContext = {
              userId: localUserData.id,
              authId: user.id,
              orgId: localUserData.org_id,
              orgName: localUserData.org_name,
              authOrgId: getOrganization(),
              accessLevel: "1",
              firstName: user.given_name,
              lastName: user.family_name,
              email: user.email,
              phoneNumber: localUserData.phone_number,
              imageUrl: localUserData.profile_url,
              onboardingComplete: localUserData.onboarding_complete,
            };
            setAuthContext(newAuthContext);
            localStorage.setItem("authContext", JSON.stringify(newAuthContext));
          })
          .catch((error) => {
            console.error("Error fetching user data:", error);
          });
      }
    } else if (!isLoading && !isAuthenticated) {
      // Redirect to login if not authenticated
      if (location.pathname !== "/auth/login") {
        navigate("/auth/login", { state: { from: location.pathname } });
      }
    }
  }, [
    isAuthenticated,
    isLoading,
    user,
    getOrganization,
    authContext.userId,
    navigate,
    location,
  ]);

  // Memoize the authContext to prevent unnecessary re-renders
  const memoizedAuthContext = useMemo(() => authContext, [authContext]);

  // Memoized handleLogout function to reset authContext and logout the user
  const handleLogout = useCallback(async () => {
    try {
      localStorage.removeItem("authContext");
      setAuthContext({
        userId: "",
        authId: "",
        orgId: "",
        orgName: "",
        authOrgId: "",
        accessLevel: 1,
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        imageUrl: "",
        onboardingComplete: "",
      });
      await logout();
    } catch (error) {
      console.error("Logout failed:", error);
    }
  }, [logout]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        getToken,
        authContext: memoizedAuthContext,
        setAuthContext,
        logout: handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
